import { environment } from './../../environments/environment';
import { AppService } from '../services/app.service';
import { Component, HostListener, OnInit, Renderer2, AfterViewInit,
  OnDestroy, HostBinding, ViewChild, ElementRef, ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router'
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  menu,header,stats,map,service,careers,footer,solutions,
  headerElements,statsElements,mapElements,faqs,faqsElements,serviceElements,careersElements,
  footerElements,solutionsElements,mapArrow,fadeOutIn,serviceFadeOutIn,mobileServiceToggl,
  desktopServiceToggl, faqsCardFade
} from './landing.animations'
import { distinctUntilChanged,Subject,takeUntil,fromEvent,take, Observable, first, TimeoutConfig, takeWhile, tap, EmptyError} from 'rxjs'
import { SafeResourceUrl,DomSanitizer } from '@angular/platform-browser';
type solutionAnimations = {
  down: (e:Event | MouseEvent | TouchEvent | boolean) => void,
  move: (e:Event | MouseEvent | TouchEvent | boolean) => void,
  up: (firstRender?: boolean, e?:Event | MouseEvent | TouchEvent) => void
}

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    menu,header, stats, map, service, careers, footer,solutions,
    headerElements,statsElements, mapElements,faqs,faqsElements,serviceElements,careersElements,
    footerElements,solutionsElements,mapArrow, fadeOutIn,serviceFadeOutIn,mobileServiceToggl,
    desktopServiceToggl, faqsCardFade
  ],
  
})

export class LandingComponent implements OnInit, AfterViewInit, OnDestroy{

  environment = environment
  
  @ViewChild('question',{static: false})
  questionInput:ElementRef | undefined
  @ViewChild('map',{static: false})
  map:ElementRef | undefined
  @ViewChild('videoConteiner',{static: false})
  mapConteiner: ElementRef | undefined
  @ViewChild("iframeForm",{static: false})
  quoteForm: ElementRef | undefined

  @ViewChild("serviceSection",{static:false})
  serviceSection: ElementRef | undefined
  @ViewChild("cardsContainer",{static: false})
  solutionsGallery: ElementRef | undefined
  @ViewChild("verticalLine",{static: false})
  verticalLine: ElementRef | undefined
  @ViewChild("solutions",{static: false})
  solutionsSection: ElementRef | undefined

  swiper: Swiper | undefined | string = undefined
  year = new Date().getFullYear()
  pixelRatio = 2;

  breakpoint$:Observable<object>;
  destroyed$ = new Subject<boolean>();
  undind$ = new Subject<string>()
  type:string = 'mobile'

  loading:boolean = true

  messageContent: string = ''

  // fastViewHide:boolean;
  arrowRotation:'horizontal' | 'vertical';

  host:string;
  link:SafeResourceUrl = '';
  linkLoaded:boolean = false
  dataUrl:string;

  menuShown:boolean = false

  selectedSection:string = 'none'

  selectedServices:string = 'DOOR-TO-DOOR'
  animationGoing:boolean = true;
  @HostBinding('@.disabled')
  public disableAnimations:boolean = false;

  touchStartY:number;
  // isDragging:boolean = false;
  startX:number;
  startY:number; scrollLeft:number; scrollTop:number;

  openedFaq: string = ''

  sectionArray:string[] = ['header','stats','map','service','solutions','careers','faqs','footer']
  selectedSections:string[] = []
  sectionIndex:number = 0
  // touchDevice:boolean = false
  supportsVideo:boolean = true
  params

  constructor(
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2, 
    private router: Router,
    private service: AppService,
    private sanitaze: DomSanitizer,
    private cdr:ChangeDetectorRef,
    private route: ActivatedRoute
    ){}

  ngOnInit():void{
    // alert(`Your width: ${window.innerWidth}, height: ${window.innerHeight}`)
    // Getting host href for quote form
    this.host = this.service.getQuoteFormHost()
    // clearing link for quote form. bypassSecurityTrustResourceUrl is required if link is provided using [src] attr
    // Also adding timeout to quote-form start loading because quote-form script is downloading 2.5 Mb what will slow page loading speed
    setTimeout(() => {
      this.link = this.sanitaze.bypassSecurityTrustResourceUrl(`${this.host}/quote-form?source=DieselDispatch&referenceId=5Wd_O7HUukC&onsuccess=thankyou&supportPhone=3605398600&sourceUrl=https://dieseldispatch.com/`)
      this.linkLoaded = true
      // Removing styles added by other resources
    if(["desktop","mac"].includes(this.type)){
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },1500)
    }
    },3000)
    
    this.dataUrl = `${this.host}/quote-form/`
    // Adding overflow:hidden and backgroundColor: black to body using renderer
    this.renderer.addClass(document.body,'black-blocked')
    // For autoplay map video, because if user didn`t interact with page, it won`t play
    fromEvent(document,'click').pipe(
      take(1)
    ).subscribe(() => {
      this.touchedOnce = true
    })
    // initialize adaptive type resize observer
    this.breakpoint$ = this.breakpointObserver
    .observe(['(min-width: 768px)','(min-width: 1024px)','(min-width: 800px)','(min-width: 1280px)','(min-width: 1280px) and (min-height: 832px), (min-width: 1400px)','(min-width: 1400px) and (min-height:900px)','(min-width: 1920px)'])
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    );
    this.breakpoint$.pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(() =>this.resizeBreakpointsHandler());

    // Page is always opening at very top, previous scroll positions(if it was) are ignored
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    } else {
      window.onbeforeunload = function () {
          window.scrollTo(0, 0);
      }
    }

    // Checking if browser supports video tag, if not playing it using img tag
    if(typeof document.createElement('video').canPlayType === 'function' && !!document.createElement('video').canPlayType){
      this.supportsVideo = true
      // alert("Your device does support video")
    }else{
      this.supportsVideo = false
      // alert("Your device does not support video")
    } 


    this.route.queryParams.subscribe(params => {this.params = params});
  }
  serviceTStart:number
  serviceMStartX:number | null
  serviceMStartY:number | null
  mapTStart:number;
  touchedDownOnce: boolean = false;
  touchedUpOnce: boolean = false
  showArrow:boolean;

  // serviceMargins:string;
  ngAfterViewInit():void{
     // Loading careers bg later than other to provide header load faster
     setTimeout(() => {
      this.lazyLoadImages()
    },3000)
   
    // Getting information about device pixel ratio for solution animation
    if([1,2,3].includes(Math.round(window.devicePixelRatio))){
      this.pixelRatio = Math.round(window.devicePixelRatio)
    }else{
      this.pixelRatio = 2
    }

    // Logic for map playing and scrolling actions(togglingSections)
      let mapWrapper = document.getElementById('map-wrapper')
      fromEvent(mapWrapper, 'touchstart').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        this.mapTStart = e.touches[0].clientY;
      })
      
      fromEvent(mapWrapper, 'touchend').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        let mapTEnd = e.changedTouches[0].clientY
  
        if (this.mapTStart-20 > mapTEnd) {
          // User swiped down
          (mapWrapper.scrollHeight - mapWrapper.clientHeight <= mapWrapper.scrollTop + 100) ? this.showArrow = true : this.touchedDownOnce = true
          this.touchedUpOnce = false
        }
        else if (this.mapTStart+30 < mapTEnd) {
          // User swiped up
          if(this.touchedUpOnce && mapWrapper.scrollTop <= 0){
            this.showPreviousSection()
            this.touchedUpOnce = false
          }else{
            this.touchedUpOnce = true
          }
          this.touchedDownOnce = false;
          this.showArrow = false
        }
      })
    
    // Logic for toggling service options with touch devices
    let serviceWrapper = document.getElementById('tape-conteiner')
    if(serviceWrapper){
      fromEvent(serviceWrapper, 'touchstart').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        this.serviceTStart = e.touches[0].clientX;;
      })
      fromEvent(serviceWrapper,'mousedown').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:MouseEvent) => {
        this.serviceMStartX = e.clientX;
        this.serviceMStartY = e.clientY;
        this.animationGoing = true
      })
      fromEvent(serviceWrapper,'mousemove').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:MouseEvent) => {
        
        if(!this.serviceMStartX && !this.serviceMStartY){
          return
        }
        let endX = e.clientX;
        let endY = e.clientY;

        let deltaX = endX - this.serviceMStartX;

        if (Math.abs(deltaX) >= 10) {
          if (deltaX > 0) {
            this.serviceTogglFunc(-1)
            this.serviceMStartX = null
            this.serviceMStartY = null;
            this.animationGoing = false
          } else {
            this.serviceTogglFunc(1)
            this.serviceMStartX = null
            this.serviceMStartY = null;
            this.animationGoing = false
          }
        }
        this.animationGoing = false
      })
      fromEvent(serviceWrapper,'mouseup').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:MouseEvent) => {
        this.serviceMStartX = null
        this.serviceMStartY = null;
      })
      
      fromEvent(serviceWrapper, 'touchend').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        let serviceTEnd = e.changedTouches[0].clientX
        let distance = serviceTEnd - this.serviceTStart
        if (distance > 30) {
          // User swiped right
          this.serviceTogglFunc(-1)
        }
        else if (distance < -30) {
          // User swiped up
          this.serviceTogglFunc(1)
        }
      })
    }
      

    if(this.type === 'mobile'){
      
      if(!(this.swiper instanceof Swiper)){
        this.swiper = new Swiper('.swiper-stats',{
          modules: [Navigation],
          direction: 'horizontal',
          slidesPerView: 3,
          speed: 800,
          effect: 'slide',
          loop: true,
          longSwipes: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        })
      }
    }else{
      this.swiper = 'none'
    }
    this.selectedSection = 'header'
    this.loading = false
    this.bindSolutionHandlers()
    this.cdr.detectChanges()
  }
  subfaqsAnimGoing:boolean = false
  faqsAnim$:Subject<void> = new Subject<void>()
  animH:string
  faqSectionIndex:number = 1;
  maxFaqSecInd:number;
  faqSettings:object;
  minFaqCardH:string
  minFaqPopH:string

  serviceOptions:{
    positions: any,
    lastServiceSection: number,
    order: any,
    titlePositions?: any
  };
  lastServiceSection;
  // Adaptive resize observer function
  private resizeBreakpointsHandler():void {
    if(this.breakpointObserver.isMatched('(min-width: 1920px)')){
      if(this.type === 'mobile'){
        this.wentFromMobile = true
      }
      this.type = 'desktop';
      this.undind$.next('desktop')
      // this.fastViewHide = false
      this.arrowRotation = 'horizontal'
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },3000)
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      // depending .list-conteiner decreasing
      this.minFaqCardH = 'clamp(111px,13.2vh,130px)'
      // depending .popular-text decreasing
      this.minFaqPopH = 'clamp(147px,18.3vh,180px)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-31%)',
          third: 'translateX(-31%)',
          last: 'translateX(-31%)',

          final: 'translateX(-31%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-34%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 3,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      // Refreshing to recalculate positions( with time out, because first call happens in ngOnInit)
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },300)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1440px)')){
      if(this.type === 'mobile'){
        this.wentFromMobile = true
      }
      this.type = 'macxl';
      this.undind$.next('macxl')
      this.arrowRotation = 'horizontal'
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },3000)
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(97px,11.8vh)'
      this.minFaqPopH = 'min(138px,16.8vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-30%)',
          third: 'translateX(-65%)',
          last: 'translateX(-65%)',
          final: 'translateX(-65%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-68%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 4,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      // Refreshing to recalculate positions
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },300)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1280px) and (min-height: 832px), (min-width: 1400px)')){
      if(this.type === 'mobile'){
        this.wentFromMobile = true
      }
      this.type = 'mac';
      this.undind$.next('mac')
      this.arrowRotation = 'horizontal'
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },3000)
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(97px,12.9vh)'
      this.minFaqPopH = 'min(138px,18.4vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-28%)',
          third: 'translateX(-62%)',
          last: 'translateX(-62%)',
          final: 'translateX(-62%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-65%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 4,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      // Refreshing to recalculate positions
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },300)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1280px)')){
      if(this.type === 'mobile'){
        this.wentFromMobile = true
      }
      this.type = 'ipadxl';
      this.undind$.next('ipadxl')
      this.arrowRotation = 'horizontal'
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(97px,12.9vh)'
      this.minFaqPopH = 'min(138px,18.4vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-28%)',
          third: 'translateX(-62%)',
          last: 'translateX(-62%)',
          final: 'translateX(-62%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-65%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 4,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      // Refreshing to recalculate positions
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },300)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1024px)')) {
      if(this.type === 'mobile'){
        this.wentFromMobile = true
      }
      this.type = 'tabletxl';
      this.undind$.next('tabletxl')
      this.arrowRotation = 'horizontal'
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(78px,12vh)'
      this.minFaqPopH = 'min(115px,17.69vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-25%)',
          third: 'translateX(-63%)',
          last: 'translateX(-104%)',
          final: 'translateX(-104%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-107%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 5,
      }
      // Set timeout because onInit ViewChild elements is not initializated in OnInit life cycle
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      // Refreshing to recalculate positions
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },300)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 800px)')){
      if(this.type === 'mobile'){
        this.wentFromMobile = true
      }
      this.type = 'ipad';
      this.undind$.next('ipad')
      this.arrowRotation = 'vertical'
      this.faqSettings = {
        1: 'ten,elev,twel,1,2,3,4,5',
        2: '9,6,7,8',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(107px,11.38vh)'
      this.minFaqPopH = 'min(140px,14.89vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-68%)',
          third: 'translateX(-120%)',
          last: 'translateX(-175%)',
          final: 'translateX(-175%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-178%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 5
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      // Refreshing to recalculate positions
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },300)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 768px)')){
      if(this.type === 'mobile'){
        this.wentFromMobile = true
      }
      this.type = 'tablet';
      this.undind$.next('tablet')
      this.arrowRotation = 'vertical'
      this.faqSettings = {
        1: 'ten,elev,twel,1,2,3,4,5',
        2: '9,6,7,8',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(100px,11.36vh)'
      this.minFaqPopH = 'min(136px,15.45vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-68%)',
          third: 'translateX(-120%)',
          last: 'translateX(-175%)',
          final: 'translateX(-175%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-178%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 5
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      // Refreshing to recalculate positions
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },300)
      this.cdr.detectChanges()
    }
    else{
      this.type = 'mobile'
      this.undind$.next('mobile')
      this.arrowRotation = 'vertical'
      this.faqSettings = {
        1: '1,2,3',
        2: 'ten,elev,twel',
        3: '7,8,9',
        4: '4,5,6'
      }
      this.maxFaqSecInd = 4
      this.minFaqCardH = 'min(11.9vw,7vh)' 
      this.minFaqPopH = 'min(18.8vw,11.08vh)'
      if(!(this.swiper instanceof Swiper) && this.swiper !== undefined){
        setTimeout(() => {
          this.swiper = new Swiper('.swiper-stats',{
            modules: [Navigation],
            direction: 'horizontal',
            slidesPerView: 3,
            speed: 800,
            effect: 'slide',
            loop: true,
            longSwipes: false,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          })
        },100)
      }
      // Refreshing to recalculate positions
      setTimeout(() => {
        this.solutionsAnimations.move(false)
        this.solutionsAnimations.down(false)
        this.solutionsAnimations.up(true)
        this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
          let type = 'mobile'
          if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
            type = 'desktop'
          }
          if(['ipad','tablet'].includes(this.type)){
            type = 'ipad'
          }
          return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
        })
        // Waiting until @for will rerender all list, and than loading all images
        setTimeout(() => {
          this.lazyLoadImages()
        },100)
      },100)
      this.cdr.detectChanges()
    }
  }
  
  isSolutionDrag:boolean = false
  prMousePos:number
  mouseDiff:number
  translate:number = 0;
  selectedSolution:number = 1
  
  bindSolutionHandlers(){
    // Creating list of availible solutions images
    this.solutionImageGallery = [1,2,3,4,5,6,7,8,9,10,11].map((num) => {
      let type = 'mobile'
      if(['desktop','macxl','mac','ipadxl','tabletxl'].includes(this.type)){
        type = 'desktop'
      }
      if(['ipad','tablet'].includes(this.type)){
        type = 'ipad'
      }
      return `../assets/solutions/cards/solution${num}-${type}@x${this.pixelRatio}.webp`
    })
    
    if(typeof MouseEvent !== 'undefined'){
      fromEvent(this.solutionsGallery.nativeElement,'mousedown').pipe(
        takeUntil(this.destroyed$),
      ).subscribe(this.solutionsAnimations.down)
      fromEvent(this.solutionsSection.nativeElement,'mousemove').pipe(
        takeUntil(this.destroyed$),
      ).subscribe(this.solutionsAnimations.move)
      fromEvent(this.solutionsSection.nativeElement,'mouseup').pipe(
        takeUntil(this.destroyed$),
      ).subscribe(this.solutionsAnimations.up)
    }
    if('ontouchstart' in document.documentElement){
      fromEvent(this.solutionsGallery.nativeElement,'touchstart').pipe(
        takeUntil(this.destroyed$)
      ).subscribe(this.solutionsAnimations.down)
      fromEvent(this.solutionsSection.nativeElement,'touchmove').pipe(
        takeUntil(this.destroyed$)
      ).subscribe(this.solutionsAnimations.move)
      fromEvent(this.solutionsSection.nativeElement,'touchend').pipe(
        takeUntil(this.destroyed$)
      ).subscribe(this.solutionsAnimations.up)
    }
  }
  solutionImageGallery:string[] = []
  wentFromMobile:boolean
  solutionsAnimations:solutionAnimations = {
    down: (e:MouseEvent | TouchEvent) => {
      this.isSolutionDrag = true
      if(e instanceof MouseEvent){
        this.prMousePos = e?.clientX || this.prMousePos
      }
      if('ontouchstart' in document.documentElement){
        if(e instanceof TouchEvent){
          this.prMousePos = e.touches[0].clientX || this.prMousePos
        }
      }
      let choosedText = document.querySelector(`[data-id="${this.selectedSolution}"]`) as HTMLElement
      this.translate = parseFloat(choosedText.style.transform.split(',')[0].replace('translate3d(','')) || 0

      // Making selected text not scaled as other
      let choosedTextSlide = choosedText.querySelector('.text-moveable') as HTMLElement
      // Unqiue animation for partners text
      if(this.selectedSolution === 1){
        this.chStl(choosedTextSlide,true,'opacity,1')
      }
      this.chStl(choosedTextSlide,true,'scale,')

      // Returning previous text to normal state without transalate
      let slidesToTranslate = [
        choosedText.previousElementSibling.previousElementSibling as HTMLElement,
        choosedText.previousElementSibling as HTMLElement,
        choosedText.nextElementSibling as HTMLElement,
        choosedText.nextElementSibling.nextElementSibling as HTMLElement
      ].forEach((slide) => {
        (slide.querySelector('.text-moveable') as HTMLElement).style.transform = 'translate3d(0px,0px,0px)';
      })

      // Unqiue animation for 5 & 10 text, because it`s too big
      if((this.selectedSolution === 5 || this.selectedSolution === 10) || this.type === 'mobile' || this.wentFromMobile){
        let corpText = document.querySelector(`[data-id="${this.selectedSolution}"] .text-moveable`) as HTMLElement
        let firstLetter = corpText.querySelector('span:first-child') as HTMLElement
        if(firstLetter){
          firstLetter.style.transform = 'translate3d(0px,0px,0px)'
          let secondLetter = corpText.querySelector('span:last-child') as HTMLElement
          secondLetter.style.transform = 'translate3d(0px,0px,0px)'
        }
        this.wentFromMobile = false
      }
    },
    move: (e:MouseEvent | TouchEvent) => {
      if(!this.isSolutionDrag){
        return
      }
      let elements = document.querySelectorAll('#text-container .text-wrapper') as NodeListOf<HTMLElement>
      if(e instanceof MouseEvent){
        this.mouseDiff = (e?.clientX || this.prMousePos) - this.prMousePos;
        this.translate = this.translate + this.mouseDiff
        this.prMousePos = e?.clientX || this.prMousePos
      }
      // First check for Safari only  
      if('ontouchstart' in document.documentElement){
         if(e instanceof TouchEvent){
          this.mouseDiff = (e?.touches[0].clientX || this.prMousePos) - this.prMousePos;
          this.translate = this.translate + this.mouseDiff
          this.prMousePos = e?.touches[0].clientX || this.prMousePos
        }
      }
      
      elements.forEach((el:HTMLElement) => {
          el.style.transform = `translate3d(${this.translate}px,0px,0px)`
      })

      // Focused element was changed
      if(this.selectedSolution !== this.checkIntersection()){
        let selectedSolutionEl = document.querySelector(`[data-id="${this.selectedSolution}"]`)
        selectedSolutionEl.classList.remove('observing')
        this.selectedSolution = this.checkIntersection()
        
        selectedSolutionEl = document.querySelector(`[data-id="${this.selectedSolution}"]`)
        selectedSolutionEl.classList.add('observing')

        // Infinity loop logic
        let firstElement:HTMLElement;
        let lastElement:HTMLElement;
        if(Number(this.selectedSolution) === Number(elements[2].dataset.id)){
          // If current choosed text is two slides away from start
          lastElement = elements[elements.length-1]
        }
        if(Number(this.selectedSolution) === Number(elements[elements.length-3].dataset.id)){
          // If current choosed text is two slides away from end
          firstElement = elements[0]
        }
        // Too close for end, cutting slide from start to end
        if(firstElement){
          // Changing list
          let parentElement = document.getElementById('text-container')
            parentElement.removeChild(firstElement)
            parentElement.append(firstElement)
            let widthOfElement = firstElement.getBoundingClientRect().width
            elements.forEach((el:HTMLElement) => {
              el.style.transform = `translate3d(${(parseFloat(el.style.transform.split(',')[0].replace('translate3d(','')) + widthOfElement)}px,0px,0px)`
              this.translate = parseFloat(el.style.transform.split(',')[0].replace('translate3d(',''))
            })

          // Changing list copy
          let copiedList = document.getElementById('text-container-copy')
          let firstCopyElement = copiedList.firstChild as HTMLElement
            copiedList.removeChild(firstCopyElement)
            copiedList.append(firstCopyElement)
            let allElements = copiedList.querySelectorAll('.text-wrapper')
            allElements.forEach((el:HTMLElement) => {
              el.style.transform = `translate3d(${(parseFloat(el.style.transform.split(',')[0].replace('translate3d(','')) - widthOfElement)}px,0px,0px)`
            })
        }
        // Too close for start, cutting slide from end to start
        if(lastElement){
          // Changing list
          let parentElement = document.getElementById('text-container')
            parentElement.removeChild(lastElement)
            parentElement.prepend(lastElement)
            let widthOfElement1 = lastElement.getBoundingClientRect().width
            elements.forEach((el:HTMLElement) => {
              el.style.transform = `translate3d(${(parseFloat(el.style.transform.split(',')[0].replace('translate3d(','')) - widthOfElement1)}px,0px,0px)`
              this.translate = parseFloat(el.style.transform.split(',')[0].replace('translate3d(',''))
            })

          // Changing list copy
          let copiedList = document.getElementById('text-container-copy')
          let lastCopyOfElement = copiedList.lastChild as HTMLElement
            copiedList.removeChild(lastCopyOfElement)
            copiedList.prepend(lastCopyOfElement)
            let widthOfElement2 = lastCopyOfElement.getBoundingClientRect().width
            let allElements = copiedList.querySelectorAll('.text-wrapper')
            allElements.forEach((el:HTMLElement) => {
              el.style.transform = `translate3d(${(parseFloat(el.style.transform.split(',')[0].replace('translate3d(','')) - widthOfElement2)}px,0px,0px)`
            })
        }
      }
      
    },
    up: async(firstRender:MouseEvent | boolean) => {
      let elements = document.querySelectorAll('#text-container .text-wrapper')
      let elementsCopy = document.querySelectorAll('#text-container-copy .text-wrapper')
      let distance = this.checkDistanceToCenter()
      
      if((!distance && !this.isSolutionDrag)){
        return
      }
      
      // Preparing to action
      this.isSolutionDrag = false
      elements.forEach((el:HTMLElement) => {

        // Centering all slides( transition here is needed )
        this.chStl(el,false,'transition,transform 0.2s linear')
        el.style.transform = `translate3d(${this.translate + distance}px,0px,0px)`

        // Animation for choosed text
        if(Number(el.dataset.id) === Number(this.selectedSolution)){
          let textElement = document.querySelector(`[data-id="${this.selectedSolution}"] .text-moveable`) as HTMLElement
          this.chStl(textElement,true,'scale,1.2')
          // Animation only for first slide
          if(this.selectedSolution === 1){
            this.chStl(textElement,true,'opacity,0')
          }
          // Setting initial position
          if(firstRender === true){
            let parentListElement = document.querySelector(`#text-container [data-id="${Number(el.dataset.id)}"]`)
            parentListElement.classList.add('observing')
          }
        }
      })

      // Centering all slides at copy to animation be corect
      elementsCopy.forEach((el:HTMLElement) => {
        // Centering all slides
        el.style.transform = `translate3d(${this.translate + distance}px,0px,0px)`
      })
      // Animation for other slide texts(using copy to immediatly calculate translate property to go to, because original is 200 ms away from getting to correct position)
      let currentTextSlide = document.querySelector(`#text-container [data-id="${this.selectedSolution}"]`) as HTMLElement
      let currentTextSlideCopy = document.querySelector(`#text-container-copy [data-id="${this.selectedSolution}"]`) as HTMLElement
      
      let nextSiblingRect = currentTextSlideCopy.nextElementSibling?.getBoundingClientRect()
      let prevoiusSiblingRect = currentTextSlideCopy.previousElementSibling?.getBoundingClientRect()

      // Size of one letter
      let textSize = parseFloat(window.getComputedStyle(
        currentTextSlideCopy.previousElementSibling?.querySelector('.text') || currentTextSlideCopy.nextElementSibling?.querySelector('.text')
      )?.getPropertyValue('font-size'))
      // Getting values to apply for side texts
      let prToTranslate = Math.round(prevoiusSiblingRect?.width - Math.abs(prevoiusSiblingRect?.left) - textSize),
          nxToTranslate = Math.round(-(nextSiblingRect?.left - window.innerWidth + textSize))
      
      // Taking pr, next and pr pr, next next
      let slidesToTranslate = [
        [currentTextSlide.previousElementSibling.previousElementSibling as HTMLElement,'prev'],
        [currentTextSlide.previousElementSibling as HTMLElement,'prev'],
        [currentTextSlide.nextElementSibling as HTMLElement,'next'],
        [currentTextSlide.nextElementSibling.nextElementSibling as HTMLElement,'next']
      ].forEach((slide) => {
        let [slideElement,direction] = slide
        if(slideElement instanceof HTMLElement){
          if(direction === 'prev'){
            let element = slideElement.querySelector('.text-moveable') as HTMLElement
            element.style.transform = `translate3d(${prToTranslate*-1}px,0px,0px)`
          }
          if(direction === 'next'){
            let element = slideElement.querySelector('.text-moveable') as HTMLElement
            element.style.transform = `translate3d(${nxToTranslate*1}px,0px,0px)`
          }
        }
      })
      
      // Unquie animation for 5 & 10 because it`s too big to be on one line, or for all texts in two lines on mobile
      if((this.selectedSolution === 5 || this.selectedSolution === 10) || this.type === 'mobile'){
        let twoLinesText = document.querySelector(`[data-id="${this.selectedSolution}"] .text-moveable`) as HTMLElement
        let firstLetter = twoLinesText.querySelector('span:first-child') as HTMLElement
        if(firstLetter){
          // For transform
          let firstLCoords = {
            top: -(firstLetter.getBoundingClientRect().height / 2),
            left: (twoLinesText.getBoundingClientRect().width - firstLetter.getBoundingClientRect().width) / 2
          }
         firstLetter.style.transform = `translate3d(${firstLCoords.left}px,${firstLCoords.top}px,0px)`
          // For transform
          let secondLetter = twoLinesText.querySelector('span:last-child') as HTMLElement
          let secondLCoords = {
            top: (secondLetter.getBoundingClientRect().height + 10) - (secondLetter.getBoundingClientRect().height / 2),
            right: -(twoLinesText.getBoundingClientRect().width - secondLetter.getBoundingClientRect().width) / 2
          }
          secondLetter.style.transform = `translate3d(${secondLCoords.right}px,${secondLCoords.top}px,0px)`
        }
        
      } 
    }
  }
  // Change styles function
  chStl(el:HTMLElement,forever:boolean,...args){
    args.forEach((style:string) => {
      let [styleToChange,propertyToChange] = style.split(',')
      // Always adding willChange to any style changing
      el.style.willChange = `${styleToChange}`
      el.style[styleToChange] = `${propertyToChange}`
      if(!forever){
        setTimeout(() => {
          el.style[styleToChange] = ''
          el.style.willChange = ''
        },200)
      }else{
        setTimeout(() => {
          el.style.willChange = ''
        },200)
      }
    })
  }
  checkDistanceToCenter():number{
    let chossedTextRect = (document.querySelector(`[data-id="${this.selectedSolution}"]`) as HTMLElement).getBoundingClientRect()
    let verticalLineRect = this.verticalLine.nativeElement.getBoundingClientRect()

    let divCenterX = chossedTextRect.left + chossedTextRect.width / 2;
    let lineCenterX = verticalLineRect.left + verticalLineRect.width / 2;
    return lineCenterX - divCenterX
  }
  checkIntersection():number{
    let verticalLineRect = this.verticalLine.nativeElement.getBoundingClientRect()
    let elIndex;
    // Selecting only previous, current & next
    document.querySelectorAll(`#text-container .text-wrapper[data-id="${this.selectedSolution}"],
    #text-container .text-wrapper[data-id="${this.selectedSolution-1 === 0 ? 11 : this.selectedSolution-1}"],
    #text-container .text-wrapper[data-id="${this.selectedSolution+1 === 12 ? 1 : this.selectedSolution+1}"]`).forEach((el:HTMLElement) => {
      let elementRect = el.getBoundingClientRect()
      if(
        verticalLineRect.right >= elementRect.left &&
        verticalLineRect.left <= elementRect.right
      ){
          elIndex = Number(el.dataset.id)
      }
    })
    return elIndex || this.selectedSolution
  }

  // Lazy loading images
  lazyLoadImages(){
    document.querySelector('.careers') ? document.querySelector('.careers').classList.add('careers-image') : undefined
    document.querySelectorAll('[data-src]').forEach((el:HTMLImageElement) => {
      if(el.src !== el.dataset.src){
        el.src = el.dataset.src
      }
    })
  }
  // Loader for video waiting(loading)
  videoLoading: boolean = false
  firstLoad: boolean = true
  videoWaiting():void{
    if(this.firstLoad){
      this.videoLoading = true
      this.firstLoad = false
    }
    
    // alert('waiting')
  }
  videoPlaying():void{
    this.videoLoading = false
    // alert('playing')
  }
  // Menu toggl logic
  menuToggl():void{
    if(this.animationGoing){
      return
    }
    this.menuShown = !this.menuShown
    this.menuShown ? this.selectedSection = 'menu' : this.selectedSection = 'header'
  }
  // Navigating to section using panel in menu
  goToSection(event):void{
    if(event.target.id === '' || event.target.id ==='getQuote' || this.animationGoing){
      return
    }
    this.animationGoing = true
    this.selectedSections.splice(this.sectionArray.indexOf(event.target.id),1)
    this.selectedSection = event.target.id
    this.sectionIndex = this.sectionArray.indexOf(event.target.id)
    this.menuShown = false
  }
  sectionDelayed:boolean = false
  // Navigating through sections functions
  showNextSection():void{
    if(this.sectionArray[this.sectionIndex + 1] && !this.animationGoing && !this.sectionDelayed){
      this.sectionDelayed = true
      this.animationGoing = true
      this.selectedSection = this.sectionArray[this.sectionIndex + 1]
      this.sectionIndex += 1
      setTimeout(() => {
        this.sectionDelayed = false
      },800)
    }
    
    // if(this.activateRoute.snapshot.queryParams['view']){
    //   this.router.navigate([''])
    // }
  }
  showPreviousSection():void{
    if(this.sectionArray[this.sectionIndex - 1] && !this.animationGoing && !this.sectionDelayed){
      this.sectionDelayed = true
      this.animationGoing = true
      this.selectedSection = this.sectionArray[this.sectionIndex - 1]
      this.sectionIndex -= 1
      setTimeout(() => {
        this.sectionDelayed = false
      },800)
    }
    // if(this.activateRoute.snapshot.queryParams['view']){
    //   this.router.navigate([''])
    // }
  }
  // Going to header
  backToTop():void{
    this.selectedSection = 'header'
    this.sectionIndex = 0
    this.refreshAnimations()
  }
  // Refreshing all animations to show them again
  refreshAnimations():void{
    this.selectedSections = []
    this.openedFaq = ''
    this.map.nativeElement.currentTime = 3
    this.map.nativeElement.pause()
  }
  // Navigate to quote-form page
  goToQuote():void{
    this.router.navigate(['quote-form'], { queryParams: this.params })
  }
  // Animation finished handler
  animationFinished(event:any):void{
    if(event.fromState != 'void' && event.element.id === this.selectedSection){
      if(event.toState === 'map'){
        if(!(this.selectedSections.includes('map'))){
          this.map.nativeElement.currentTime = 3;
          (this.map.nativeElement as HTMLVideoElement).muted = true;
          (this.map.nativeElement as HTMLVideoElement).playsInline = true;
          (this.map.nativeElement as HTMLVideoElement).play().then(() => {
            this.touchedOnce = true
          })
        }
      }
      if(event.fromState === 'map'){
        this.touchedDownOnce = false
        this.showArrow = false
      }
      if(event.toState === 'faqs' && ['map','menu'].includes(event.fromState) && !(this.selectedSections.includes('faqs'))){
        if(this.type === 'mobile'){
          this.openedFaq = this.faqSettings[this.faqSectionIndex].split(',')[1]
        }else{
          this.openedFaq = this.faqSettings[this.faqSectionIndex].split(',')[0]
        }
        this.subfaqsAnimGoing = true
        setTimeout(() => {
          this.subfaqsAnimGoing = false
        },1000)
      }
      if(event.totalTime === 0 && !this.disableAnimations){
          this.animationGoing = false
      }
      if(!this.selectedSections.includes(event.toState)){
        this.selectedSections.push(event.toState)
      }
      
    this.animationGoing = false
    }
    // refresh only when came from stats
    if(event.fromState !== 'void' && event.fromState !== 'none' && event.toState === 'header' && event.triggerName === 'statsElements'){
      this.refreshAnimations()
    }
  }
  togglFaqs(id){
    if(this.subfaqsAnimGoing || this.animationGoing){
      return
    }
    if(this.openedFaq === ''){
      this.subfaqsAnimGoing = true
      setTimeout(() => {
        this.subfaqsAnimGoing = false
      },500)
      this.openedFaq = id
      // console.log('No FAQ was choosed before');
      this.faqsAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.animH = null
          this.cdr.detectChanges()
        }
      })
      return
    }
    if(this.openedFaq === id){
      this.subfaqsAnimGoing = true
      setTimeout(() => {
        this.subfaqsAnimGoing = false
      },500)
      let currentPlayedH:any = document.querySelector(`#card-${id} [data-back]`)
      if(currentPlayedH){
        this.animH = `${currentPlayedH.offsetHeight}px`
      }
      this.openedFaq = ''
      this.cdr.detectChanges()
      // console.log('The same FAQ was choosed');
      this.faqsAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.animH = null
          this.cdr.detectChanges()
        }
      })
      return
    }
    let currentPlayedH:any = document.querySelector(`#card-${this.openedFaq} [data-back]`)
    if(currentPlayedH){
      this.animH = `${currentPlayedH.offsetHeight}px`
    }
    this.openedFaq = ''
    this.cdr.detectChanges()
    // We need to wait untill other animation is finished
    this.faqsAnim$.pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.openedFaq = id
        this.animH = null
      }
    })
  }
  faqsAnimFinish(e){
    this.faqsAnim$.next()
  }
  showFaqCard(id):boolean{
    // mobile - by 1,2,3 -> 4,5,6 -> 7,8,9 -> 10,11,12
    // tablet,ipad - by 1,2,3,4,5,6,7,8 -> 9,10,11,12
    // tabletxl,ipadxl,mac,macxl,desktop - by 1,2,3,4,5,6,7,8,9 -> 10,11,12
    if(this.faqSettings[this.faqSectionIndex].includes(id)){
      return true
    }
    return false
  }
  morelessFaqs(option:number){
    if(this.subfaqsAnimGoing || this.animationGoing){
      return
    }
    this.faqSectionIndex += option
    setTimeout(() => {
      this.openedFaq = ''
      this.animH = null
    },700)
  }
  serviceIndex:number = 1;
  serviceAnim$:Subject<void> = new Subject<void>()
  serviceAnimGoing:boolean = false
  serviceTogglFunc(option:number){
    if(this.serviceAnimGoing){
      return
    }
    if(this.type === 'mobile'){
      if(this.serviceIndex + option > 5){
        this.serviceIndex = 6
        this.serviceAnim$.pipe(
          take(1)
        ).subscribe({
          next: () => {
              this.serviceIndex = 1
              this.cdr.detectChanges()
            // }
          }
        })
        return
      }
      if(this.serviceIndex + option < 1){
        this.serviceIndex = 0
        // this.serviceAnimGoing = true
        this.serviceAnim$.pipe(
          take(1)
        ).subscribe({
          next: () => {
              this.serviceIndex = 5
              this.cdr.detectChanges()
          }
        })
        return
      }
      this.serviceIndex += option
      this.serviceAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.serviceAnimGoing = false
        }
      })
    }
    if(this.type !== 'mobile'){
      this.serviceAnimGoing = true
      if(this.serviceIndex + option === this.serviceOptions.lastServiceSection){
        this.serviceIndex = 9
      }else{
        this.serviceIndex += option
      }
      this.serviceAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.serviceIndex === 9 ? this.serviceIndex = this.serviceOptions.lastServiceSection - 1 : undefined
          this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
          if(this.serviceIndex === 0){
            this.serviceIndex = 1
            this.cdr.detectChanges()
          }
          this.serviceAnimGoing = false
        }
      })
    }
  }
  serviceAnimFinish(e){
    if(
      [0,1,2,3,4,5,6,9].includes(e.fromState) &&
      [0,1,2,3,4,5,6,9].includes(e.toState)
    ){
      this.serviceAnim$.next()
    }
    if(
      (e.fromState === 0 || e.fromState === 6) &&
      (this.type === 'mobile')
    ){
        this.serviceAnimGoing = false
    }
  }
  serviceAnimStart(e){
    if(e.toState === 0 || e.toState === 6){
      this.serviceAnimGoing = true
    }
  }
  
  // Question input data send logic
  async postMessage():Promise<void>{
    if(this.messageContent.length < 2){
      return
    }
    this.loading = true
    this.service.postMessage(this.messageContent).pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      {
        next: (res) => {
          if(res.data.success){
            console.log("%cYour message was succesfully sent!","color:#9ed870")
            this.messageContent = ''
            this.questionInput.nativeElement.placeholder = 'Your message was succesfully sent!'
            this.questionInput.nativeElement.classList.add('success')
          }
          this.loading = false
        },
        error:(err) => {
          console.error(err)
          alert(`We are apologize but it seems some error occurred on our side.\nWe will fix it asap \n"${err.error.message}"`)
          this.loading = false
        }
      }
    )
  }
  touchStartX:number;
  touchedOnce = false
  // Detecting swipe direction
  @HostListener('document:touchstart', ['$event','passive'])
  onTouchStart(event: TouchEvent):void {
      this.touchStartY = event.touches[0].clientY;
      this.touchStartX = event.touches[0].clientX;
  }
  @HostListener('document:touchend', ['$event','passive'])
  onTouchEnd(event: TouchEvent):void {
    if(event.target instanceof Element ?
        event.target.classList.contains('map-video') : undefined){
      return
    }
    if(this.animationGoing || this.menuShown){
      return
    }
    const touchEndY = event.changedTouches[0].clientY;
    const touchEndX = event.changedTouches[0].clientX
    
    // User can toggl section only when his swipe by y axis more than by x axis
    let canToggl = Math.abs(touchEndX - this.touchStartX) < Math.abs(touchEndY - this.touchStartY)
    
    if (this.touchStartY-90 > touchEndY && canToggl) {
      // User swiped down
      this.showNextSection();
    } else if (this.touchStartY+90 < touchEndY && canToggl) {
      // User swiped up
      this.showPreviousSection();
    }
  }
  // Detection mousewheel direction ( Date.now() check is added because of inertial wheel on IOS/Mac)
  lastDate:number = Date.now()
  @HostListener('document:mousewheel', ['$event','passive'])
  onMouseWheel(event: WheelEvent):void {

    if(this.animationGoing || this.menuShown || Math.abs((Date.now() - this.lastDate)) <= 50){
      this.lastDate = Date.now()
      return
    }
    this.lastDate = Date.now()
    if (event.deltaY < 0) {
      // User is scrolling up
      
      this.showPreviousSection()
    } else if (event.deltaY > 0) {
      // User is scrolling down
      this.showNextSection()
    }
  }
  ngOnDestroy():void{
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.undind$.next('destroyed')
    this.undind$.complete()
  }
}
